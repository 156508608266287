
import '../App.css';
import Navbar from "./Navbar";
import { TableCellsIcon,PresentationChartBarIcon,ClipboardDocumentListIcon,UserGroupIcon,ClipboardDocumentCheckIcon} from '@heroicons/react/24/solid'
import {useNavigate} from "react-router-dom";

import {useEffect, useState} from "react";
import './gaya.css'
import Wave from 'react-wavify';
import { useQuery } from 'react-query';
import axios from 'axios';
import baseUrl from "../config";

function App() {
    const [admin, setAdmin] = useState({});
    const navigate = useNavigate();





    const [selectedTA, setSelectedTA] = useState(() => {
        const stored = localStorage.getItem('dataTahunAjaran');
        if (stored) {
            const { tahunAjaran } = JSON.parse(stored);
            console.log('Initial TA from localStorage:', tahunAjaran);
            return tahunAjaran;
        }
        console.log('Using default TA: 2023-2024');
        return '2023-2024';
    });

    const [selectedSemester, setSelectedSemester] = useState(() => {
        const stored = localStorage.getItem('dataTahunAjaran');
        if (stored) {
            const { semester } = JSON.parse(stored);
            console.log('Initial semester from localStorage:', semester);
            return semester;
        }
        console.log('Using default semester: sms1');
        return '1';
    });

    const { data, isLoading, isError } = useQuery('tahunAjaran', async () => {
        const response = await axios.get(`${baseUrl}/list-ta`);
        return response.data;
    });

    useEffect(() => {
        const dataToSave = JSON.stringify({
            tahunAjaran: selectedTA,
            semester: selectedSemester
        });
        console.log('Saving to localStorage:', dataToSave);
        localStorage.setItem('dataTahunAjaran', dataToSave);
    }, [selectedTA, selectedSemester]);
    
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setAdmin(user);
        }
    }, [], );

    const handleTAChange = (e) => {
        const newTA = e.target.value;
        console.log('Changing TA to:', newTA);
        setSelectedTA(newTA);
    };

    const handleSemesterChange = (e) => {
        const newSemester = e.target.value;
        console.log('Changing semester to:', newSemester);
        setSelectedSemester(newSemester);
    };

    const tahunAjaranOptions = isError || !data?.tahunAjaran ? ['2023-2024'] : data.tahunAjaran;

    console.log('Current state:', { selectedTA, selectedSemester });

    return (
        <div>
            <Navbar/>

            <div className="relative mr-3 ml-3 mt-3 p-6 max-w-lg mx-auto bg-gradient-to-r from-indigo-400 to-indigo-700 rounded-xl shadow-lg  items-center overflow-hidden">
                {/* Wave Animation */}
                <svg style={{ position: 'absolute', top: '100%', left: 0, right: 0 }}>
                    <defs>
                        <linearGradient id="gradient" gradientTransform="rotate(90)">
                            <stop offset="20%" stopColor="#ffcba4" />
                            <stop offset="80%" stopColor="#ffb347" />
                        </linearGradient>
                    </defs>
                </svg>

                {/* Wave Animation */}
                <Wave fill='url(#gradient)'
                      paused={false}
                      options={{
                          height: 125,
                          amplitude: 20,
                          speed: 0.15,
                          points: 3
                      }}
                      className="shadow-xl absolute bottom-0 left-0 w-full"
                />

                {/* Overlay to make the wave appear rounded */}
                <div className="absolute border-black bottom-0 left-0 right-0 h-12  rounded-b-lg"></div>

                <h1 className="text-2xl italic text-center text-white z-10 relative  font-poppins">
                    Selamat datang,<br/> {admin.nama || 'Pengguna'}!
                </h1>
            </div>





            <div className="mr-3 ml-3 mt-3 p-6 max-w-lg mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 border border-indigo-400">
                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <label htmlFor="tahunAjaran" className="ml-3 block text-xs font-medium text-gray-700">Tahun Ajaran:</label>
                        <select
                            id="tahunAjaran"
                            value={selectedTA}
                            onChange={handleTAChange}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base bg-white border-gray-100 border focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        >
                            {tahunAjaranOptions.map((ta) => (
                                <option key={ta} value={ta}>
                                    {ta}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className="block text-xs font-medium text-gray-700">Semester:</label>
                        <div className="flex space-x-4 mt-3">
                            <div className="flex items-center">
                                <input
                                    id="sms1"
                                    name="semester"
                                    type="radio"
                                    checked={selectedSemester === '1'}
                                    onChange={handleSemesterChange}
                                    value="1"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                />
                                <label htmlFor="sms1" className="ml-2 text-sm font-medium text-gray-700">1</label>
                            </div>
                            <div className="flex items-center">
                                <input
                                    id="sms2"
                                    name="semester"
                                    type="radio"
                                    checked={selectedSemester === '2'}
                                    onChange={handleSemesterChange}
                                    value="2"
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                />
                                <label htmlFor="sms2" className="ml-2 text-sm font-medium text-gray-700">2</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>









            <a onClick={()=>{navigate('/rapot') }}>
                <div className="mr-3 ml-3 mt-3 p-6 max-w-lg mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 border border-orange-400">
                    <div className="shrink-0">
                        <UserGroupIcon className="h-10 w-10 text-orange-400" aria-hidden="true" />

                    </div>

                    <div>
                        <div className="text-xl font-medium text-black">Data Murid</div>
                        <p className="text-slate-500">Tambah, Hapus dan Edit Data Murid disini </p>
                    </div>

                </div>
            </a>



            <a onClick={()=>{navigate('/rekap-nilai') }}>
                <div className="mr-3 ml-3 mt-3 p-6 max-w-lg mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 border border-orange-400">
                    <div className="shrink-0">
                        <TableCellsIcon className="h-10 w-10 text-orange-400" aria-hidden="true"  />

                    </div>

                    <div>
                        <div className="text-xl font-medium text-black">Rekap Nilai</div>
                        <p className="text-slate-500">Catat, lihat dan edit nilai Ujian semester/Rapot Murid </p>
                    </div>

                </div>
            </a>
            {/*<a onClick={()=>{navigate('/taftisan') }}>*/}
            {/*    <div className="mr-3 ml-3 mt-3 p-6 max-w-lg mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 border border-orange-400">*/}
            {/*        <div className="shrink-0">*/}
            {/*            <ClipboardDocumentCheckIcon className="h-10 w-10 text-orange-400" aria-hidden="true"  />*/}

            {/*        </div>*/}

            {/*        <div>*/}
            {/*            <div className="text-xl font-medium text-black">Panel Ujian</div>*/}
            {/*            <p className="text-slate-500">Fungsi / Alat untuk Ujian semester Madrasah Diniyah Darussaadah </p>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</a>*/}


            {/*{*/}

            {/*    <a onClick={()=>{navigate('/informasi') }}>*/}
            {/*        <div className="mr-3 ml-3 mt-3 p-6 max-w-lg mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4 border border-orange-400">*/}
            {/*            <div className="shrink-0">*/}
            {/*                <PresentationChartBarIcon className="h-10 w-10 text-orange-400" aria-hidden="true"  />*/}
            {/*            </div>*/}

            {/*            <div>*/}
            {/*                <div className="text-xl font-medium text-black">Informasi</div>*/}
            {/*                <p className="text-slate-500">Jadwal pelajaran, Jadwal Masuk, Laporan Absensi bulanan dll </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </a>*/}
            {/*}*/}


            {/*<a onClick={()=>{navigate('/absensi') }}>*/}
            {/*    <div className="mb-3 mr-3 ml-3 mt-3 p-6 max-w-lg mx-auto bg-white rounded-xl shadow-lg flex items-center space-x-4">*/}
            {/*        <div className="shrink-0">*/}
            {/*            <ClipboardDocumentListIcon className="h-10 w-10" aria-hidden="true" color="orange" />*/}

            {/*        </div>*/}

            {/*        <div>*/}
            {/*            <div className="text-xl font-medium text-black">Absensi</div>*/}
            {/*            <p className="text-slate-500">Alat Absensi Musama </p>*/}
            {/*        </div>*/}

            {/*    </div>*/}
            {/*</a>*/}


        </div>
    );
}

export default App;
