import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Beranda from "./pages/Beranda";
import AuthRedirect from "./pages/auth/AuthRedirect";
import {Auth} from "./pages/auth/Auth";

import RekapNilai from "./pages/RekapNilai";
import RekapRapot from "./pages/RekapRapot";

function App() {
  return (
      <div>
        <BrowserRouter>
          <Routes>
              <Route path="/" element={< AuthRedirect/>} />
              <Route path="/beranda" element={< Beranda/>} />
              <Route path="/auth" element={< Auth/>} />
              <Route path="/rekap-nilai" element={< RekapNilai/>} />
              <Route path="/rapot" element={< RekapRapot/>} />

          </Routes>
        </BrowserRouter>
      </div>
  );
}

export default App;
