import React, {useEffect, useRef, useState} from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Tab } from '@headlessui/react';
import 'tailwindcss/tailwind.css';
import baseUrl from "../config";
import Navbar from "./Navbar";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Trash, Trash2} from "lucide-react";
import {BackspaceIcon} from "@heroicons/react/16/solid";


const Modal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50">
            {/* Outer Container */}
            <div className="max-w-screen-sm bg-white rounded-lg shadow-lg w-screen max-h-screen overflow-y-auto mx-5 mt-10">
                {/* Modal Title */}


                <div className="ml-4 mt-4 flex justify-between mb-6">
                    <h2 className="text-xl font-semibold ">{title}</h2>
                    <button
                        onClick={onClose}
                        className="mr-4 p-2 bg-red-500 text-white rounded"
                    >
                        Tutup
                    </button>
                </div>
                {/* Scrollable Modal Content */}
                <div className="p-2 mb-10 overflow-y-auto">
                    {children}
                </div>

                {/* Close Button */}

            </div>
        </div>
    );
};

const RekapNilai = () => {


    // State management
    const [selectedRadio, setSelectedRadio] = useState('Pa');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedInfoClass, setSelectedInfoClass] = useState('Silahkan Pilih Kelas');
    const [dataTahunAjaran, setDataTahunAjaran] = useState({ semester: '', tahunAjaran: '' });
    const [listMapelPeserta, setListMapelPeserta] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMapel, setSelectedMapel] = useState(null); // Selected mapel for editing
    const [editedRows, setEditedRows] = useState({}); // Stores changes made to isi_nilai
    const [listNilaiRapotPeserta, setListNilaiRapotPeserta] = useState([]);
    const [listNilaiRapotPesertaRerata, setListNilaiRapotPesertaRerata] = useState([]);
    const [isModalRapotOpen, setIsModalRapotOpen] = useState(false); // Modal state

    const [opsiKelas, setOpsiKelas] = useState({
        Nahwu: null,
        Shorof: null,
        Tambahan: null,
    });

    // Get dataTahunAjaran from localStorage
    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('dataTahunAjaran'));
        if (storedData) {
            setDataTahunAjaran(storedData);
        }

        const savedData = localStorage.getItem('dataKelas');
        if (savedData) {
            setOpsiKelas(JSON.parse(savedData));
        }

    }, []);

    // Store refs for each input field using useRef and an array
    const inputRefs = useRef([]);

    useEffect(() => {
        // Initialize refs for each participant (peserta)
        inputRefs.current = listMapelPeserta?.listPesertaWithNilaiMapel.map(() => React.createRef());
    }, [listMapelPeserta]);

    const clearInput = (id_peserta, index) => {
        // Clear the input value and focus the input field
        handleIsiNilaiChange(id_peserta, '');
        if (inputRefs.current[index]) {
            inputRefs.current[index].current.value = ''; // Clear the value in the input
            inputRefs.current[index].current.focus(); // Set focus to the input
        }
    };


    // Fetch listKelas using react-query
    const { data: listKelas, refetch } = useQuery(
        ['listKelas', dataTahunAjaran],
        async () => {
            const response = await axios.get(`${baseUrl}/list-kelas`, {
                params: {
                    semester: dataTahunAjaran.semester,
                    tahunAjaran: dataTahunAjaran.tahunAjaran,
                },
            });


            return response.data.listKelas;
        },
        {
            enabled: !!dataTahunAjaran.semester && !!dataTahunAjaran.tahunAjaran, // Only fetch when data is available
        }
    );

    const filteredKelas = listKelas?.filter((kelas) => kelas.nama_kelas.includes(selectedRadio)) || [];


    const fetchRapotData = async () => {
        const response = await axios.get(`${baseUrl}/hitung-rapot-batch`, {
            params: { semester:dataTahunAjaran.semester,
                tahunAjaran:dataTahunAjaran.tahunAjaran,
                id_kelas:selectedClass,
                opsi_hafalan_nahwu:opsiKelas.Nahwu,
                opsi_hafalan_shorof:opsiKelas.Shorof,
                opsi_hafalan_tambahan:opsiKelas.Tambahan
            },
        });
        return response.data;
    };

    const { refetch: fetchRapot, isLoading:isLoadingRapot, isError:isErrorRapot, data:datafetchRapot } = useQuery('rapotData', fetchRapotData, {
        enabled: false, // Only run when refetch is called
        onSuccess: (data) => {
            console.log(data)
            setListNilaiRapotPeserta(data.dataNilaiRapot);
            setListNilaiRapotPesertaRerata(data.dataRerata);
            setIsModalRapotOpen(true); // Open the modal after data is fetched
        },
    });

    const handleBuatRapot = () => {
        fetchRapot();
        localStorage.setItem('dataKelas', JSON.stringify(opsiKelas));
        console.log('dataKelas:', opsiKelas);
    };

    const closeModalRapot = () => {
        setIsModalRapotOpen(false);
    };


    const getBgColorByKategoriFan = (kategori_fan) => {
        if (kategori_fan === 10) return 'bg-cyan-200';
        if (kategori_fan === 20) return 'bg-teal-200';
        if (kategori_fan === 30) return 'bg-purple-200';
        if (kategori_fan === 40) return 'bg-red-300';
        if (kategori_fan === 50) return 'bg-green-200';
        if (kategori_fan === 60) return 'bg-green-400';
        if (kategori_fan === 70) return 'bg-yellow-400';
        if (kategori_fan === 80) return 'bg-gray-100';
        if (kategori_fan === 90) return 'bg-teal-500';
        return 'bg-gray-100'; // Default
    };


    // Function to handle "Proses" button click
    const handleProses = async () => {
        if (!selectedClass) {
            console.log("Please select a class first.");
            setSelectedInfoClass("Silahkan Pilih Kelas..")
            return;
        }

        try {
            const response = await axios.get(`${baseUrl}/list-mapels-peserta`, {
                params: {
                    semester: dataTahunAjaran.semester,
                    tahunAjaran: dataTahunAjaran.tahunAjaran,
                    id_kelas: selectedClass,
                },
            });

            setListMapelPeserta(response.data);
            const dataToSave = JSON.stringify({
                tahunAjaran: dataTahunAjaran.tahunAjaran,
                semester: dataTahunAjaran.semester,
                id_kelas:selectedClass
            });
            localStorage.setItem('dataTahunAjaran',dataToSave)
            console.log(dataToSave)
            setSelectedInfoClass(`${listKelas?.find((kelas) => kelas.id_kelas === selectedClass)?.nama_kelas || "Tidak Ditemukan"}, Semester ${dataTahunAjaran.semester}  ${dataTahunAjaran.tahunAjaran}`)

            toast.success("Berhasil Mengambil data!",{autoClose:200});

        } catch (error) {
            console.error("Error fetching mapel peserta", error);
        }
    };

    // Open modal for editing a specific mapel
    const handleEdit = (id_mapel, nama_mapel, sesi_ujian) => {
        setSelectedMapel({ id_mapel, nama_mapel, sesi_ujian });
        setIsModalOpen(true);
    };

    // Handle isi_nilai changes and activate "Simpan" button
    const handleIsiNilaiChange = (id_peserta, newIsiNilai) => {
        setEditedRows((prev) => ({
            ...prev,
            [id_peserta]: newIsiNilai,
        }));
    };

    // Handle "Simpan" button click
    const handleSimpan = async (id_peserta) => {
        if (editedRows[id_peserta] === undefined) return; // Only proceed if there's an edited value
        const pesertaMap = listMapelPeserta.listPesertaWithNilaiMapel.reduce((acc, peserta) => {
            acc[peserta.id_peserta] = peserta.nama;
            return acc;
        }, {});

        try {
            await axios.patch(`${baseUrl}/update-individual-nilai`, {
                id_peserta,
                id_mapel: selectedMapel.id_mapel,
                isi_nilai: editedRows[id_peserta],
            });
            console.log(`Successfully updated nilai for id_peserta: ${id_peserta}`);
            toast.success(`Nilai ${pesertaMap[id_peserta]} berhasil disimpan`,{autoClose:400});
        } catch (error) {
            console.error("Error updating nilai", error);
        }
    };

    // Handle "Tutup" button click (Close modal and refetch data)
    const handleCloseModal = async () => {
        setIsModalOpen(false);
        setEditedRows({}); // Reset edited rows

        // Refetch the list-mapels-peserta data
        await handleProses();
    };



    const handleOpsiKelasChange = (event) => {
        const { name, value } = event.target;
        setOpsiKelas((prevState) => ({
            ...prevState,
            [name]: value === 'yes' ? true : false,
        }));
    };


    return (
        <>
            <ToastContainer/>
            <Navbar/>

        <div className="px-4 py-2 max-w-screen-md ">

            {/* Dropdown */}
            <div className="mb-2 flex items-center  justify-between border border-blue-800 px-4 py-2 rounded-lg">

                <label className="mr-1">
                    <input
                        type="radio"
                        value="Pa"
                        checked={selectedRadio === 'Pa'}
                        onChange={() => setSelectedRadio('Pa')}
                        className="mr-2"
                    />
                    Pa
                </label>
                <label>
                    <input
                        type="radio"
                        value="Pi"
                        checked={selectedRadio === 'Pi'}
                        onChange={() => setSelectedRadio('Pi')}
                        className="mr-2 text"
                    />
                    Pi
                </label>
                <select
                    value={selectedClass}
                    onChange={(e) => setSelectedClass(e.target.value)}
                    className="p-2 border border-gray-300 rounded-xl bg-white"
                >
                    <option value="">Pilih Kelas</option>
                    {filteredKelas?.map((kelas) => (
                        <option key={kelas.id_kelas} value={kelas.id_kelas}>
                            {kelas.nama_kelas}
                        </option>
                    ))}
                </select>

                <button
                    onClick={handleProses}
                    className="py-2 px-3 bg-indigo-700 text-white rounded-md"
                >
                    Proses
                </button>
            </div>

            {/* Process button */}



            {/* Tabs */}
            <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-md bg-blue-900/20 p-1">
                    <Tab className={ ({ selected }) =>
                    `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
                    }>Ujian Tulis</Tab>
                    <Tab className={({ selected }) =>
                        `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
                    }>Keseharian</Tab>
                    <Tab className={({ selected }) =>
                        `w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700 ${selected ? 'bg-white shadow' : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'}`
                    }>Rapot</Tab>
                </Tab.List>

                <Tab.Panels>
                    <Tab.Panel>
                        <h2 className="font-medium mt-2 text-center">
                            {selectedInfoClass}
                        </h2>
                        <div className="mt-2">
                            {/* Thamrin Table */}
                            <h2 className="text-lg font-semibold mb-2">Ujian Tulis Thamrin:</h2>
                            <table className="w-full mb-4 border border-gray-200">
                                <thead className={`bg-yellow-300`}>
                                <tr>
                                    <th className="border px-2 py-1">#</th>
                                    <th className="border px-2 py-1 ">Nama Fan</th>
                                    <th className="border px-2 py-1">Aksi</th>
                                </tr>
                                </thead>
                                <tbody className={`text-center`}>
                                {listMapelPeserta?.listSesiThamrin.map((mapel, index) => (
                                    <tr key={mapel.id_mapel}>
                                        <td className="border px-2 py-1">{index + 1}</td>
                                        <td className="border px-2 py-1 font-bold">{mapel.nama_mapel}</td>
                                        <td className="border px-2 py-1">
                                            <button
                                                className="p-1 bg-yellow-400 text-gray-800 rounded px-3 py-2"
                                                onClick={() => handleEdit(mapel.id_mapel, mapel.nama_mapel, 'Thamrin')}
                                            >
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mt-2">

                            <h2 className="text-lg font-semibold mb-2">Ujian Tulis Semester:</h2>
                            <table className="w-full mb-4 border border-gray-200">
                                <thead className={`bg-yellow-300`}>
                                <tr>
                                    <th className="border px-2 py-1">#</th>
                                    <th className="border px-2 py-1 ">Nama Fan</th>
                                    <th className="border px-2 py-1">Aksi</th>
                                </tr>
                                </thead>
                                <tbody className={`text-center`}>
                                {listMapelPeserta?.listSesiSemester.map((mapel, index) => (
                                    <tr key={mapel.id_mapel}>
                                        <td className="border px-2 py-1">{index + 1}</td>
                                        <td className="border px-2 py-1 font-bold">{mapel.nama_mapel}</td>
                                        <td className="border px-2 py-1">
                                            <button
                                                className="p-1 bg-yellow-400 text-gray-800 rounded px-3 py-2"
                                                onClick={() => handleEdit(mapel.id_mapel, mapel.nama_mapel, 'Semester')}
                                            >
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>


                    </Tab.Panel>

                    <Tab.Panel>
                        <h2 className="font-medium mt-2 text-center">
                            {selectedInfoClass}
                        </h2>
                        <div className="mt-2">

                            <h2 className="text-lg font-semibold mb-2">Nilai Keseharian</h2>
                            <table className="w-full mb-4 border border-gray-200">
                                <thead className={`bg-yellow-300`}>
                                <tr>
                                    <th className="border px-2 py-1">#</th>
                                    <th className="border px-2 py-1 ">Kategori Nilai</th>
                                    <th className="border px-2 py-1">Aksi</th>
                                </tr>
                                </thead>
                                <tbody className={`text-center`}>
                                {listMapelPeserta?.listSesiHarian.map((mapel, index) => (
                                    <tr key={mapel.id_mapel}>
                                        <td className="border px-2 py-1">{index + 1}</td>
                                        <td className="border px-2 py-1 font-bold">{mapel.nama_mapel}</td>
                                        <td className="border px-2 py-1">
                                            <button
                                                className="p-1 bg-yellow-400 text-gray-800 rounded px-3 py-2"
                                                onClick={() => handleEdit(mapel.id_mapel, mapel.nama_mapel, '')}
                                            >
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>


                    </Tab.Panel>


                    <Tab.Panel>

                        <div className="mt-4">

                            <h2 className="text-lg font-semibold mb-2">Keaktifan Santri</h2>
                            <table className="w-full mb-4 border border-gray-200">
                                <thead className={`bg-yellow-300`}>
                                <tr>
                                    <th className="border px-2 py-1 text-xs">No</th>
                                    <th className="border px-2 py-1 ">Status Keaktifan</th>
                                    <th className="border px-2 py-1">Aksi</th>
                                </tr>
                                </thead>
                                <tbody className={`text-center`}>
                                {listMapelPeserta?.listSesiRapot.map((mapel, index) => (
                                    <tr key={mapel.id_mapel}>
                                        <td className="border px-2 py-1">{index + 1}</td>
                                        <td className="border px-2 py-1 font-bold">{mapel.nama_mapel}</td>
                                        <td className="border px-2 py-1">
                                            <button
                                                className="p-1 bg-yellow-400 text-gray-800 rounded px-3 py-2"
                                                onClick={() => handleEdit(mapel.id_mapel, mapel.nama_mapel, '')}
                                            >
                                                Edit
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-md border border-blue-200">
                            <h2 className="text-lg mb-4 italic">Apakah Anda ingin menyertakan nilai-nilai berikut pada Rapot?</h2>

                            {/* Nahwu */}
                            <div className="mb-4 flex items-center">
                                <label className="mr-4 font-semibold w-36">Hafalan Nahwu</label>
                                <div className="flex items-center">
                                    <label className="mr-4 flex items-center">
                                        <input
                                            type="radio"
                                            name="Nahwu"
                                            value="yes"
                                            checked={opsiKelas.Nahwu === true}
                                            onChange={handleOpsiKelasChange}
                                            className="mr-1"
                                        />
                                        Ya
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="Nahwu"
                                            value="no"
                                            checked={opsiKelas.Nahwu === false}
                                            onChange={handleOpsiKelasChange}
                                            className="mr-1"
                                        />
                                        Tidak
                                    </label>
                                </div>
                            </div>

                            {/* Shorof */}
                            <div className="mb-4 flex items-center">
                                <label className="mr-4 font-semibold w-36">Hafalan Shorof</label>
                                <div className="flex items-center">
                                    <label className="mr-4 flex items-center">
                                        <input
                                            type="radio"
                                            name="Shorof"
                                            value="yes"
                                            checked={opsiKelas.Shorof === true}
                                            onChange={handleOpsiKelasChange}
                                            className="mr-1"
                                        />
                                        Ya
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="Shorof"
                                            value="no"
                                            checked={opsiKelas.Shorof === false}
                                            onChange={handleOpsiKelasChange}
                                            className="mr-1"
                                        />
                                        Tidak
                                    </label>
                                </div>
                            </div>

                            {/* Tambahan */}
                            <div className="mb-4 flex items-center">
                                <label className="mr-4 font-semibold w-36 ">Hafalan Tambahan</label>
                                <div className="flex items-center">
                                    <label className="mr-4 flex items-center">
                                        <input
                                            type="radio"
                                            name="Tambahan"
                                            value="yes"
                                            checked={opsiKelas.Tambahan === true}
                                            onChange={handleOpsiKelasChange}
                                            className="mr-1"
                                        />
                                        Ya
                                    </label>
                                    <label className="flex items-center">
                                        <input
                                            type="radio"
                                            name="Tambahan"
                                            value="no"
                                            checked={opsiKelas.Tambahan === false}
                                            onChange={handleOpsiKelasChange}
                                            className="mr-1"
                                        />
                                        Tidak
                                    </label>
                                </div>
                            </div>
                        </div>


                        <div className="mt-4 text-center">
                            <button
                                onClick={handleBuatRapot}
                                className="bg-blue-500 text-white px-4 py-2 rounded mb-6"
                            >
                                Buat Rapot
                            </button>

                            {isLoadingRapot && <p>Loading...</p>}
                            {isErrorRapot && <p>Error fetching data...</p>}



                        </div>


                    </Tab.Panel>

                    {/* Similar table structure for Keseharian (Harian Table) and Rapot */}
                </Tab.Panels>
            </Tab.Group>

            {/* Reusable Modal */}
            <Modal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                title={`Edit ${selectedMapel?.nama_mapel} ${selectedMapel?.sesi_ujian}`}
            >
                <table className="w-full mb-4 border border-gray-300">
                    <thead>
                    <tr>
                        <th className="border px-2 py-1">#</th>
                        <th className="border px-2 py-1">Nama Peserta</th>
                        <th className="border px-2 py-1">Hapus</th>
                        <th className="border px-2 py-1">Isi Nilai</th>
                        <th className="border px-2 py-1">Aksi</th>
                    </tr>
                    </thead>
                    <tbody>
                    {listMapelPeserta?.listPesertaWithNilaiMapel.map((peserta, index) => {
                        const mapel = peserta.mapels.find((m) => m.id_mapel === selectedMapel?.id_mapel);
                        if (!mapel) return null;

                        return (
                            <tr key={peserta.id_peserta} className={` ${index % 2 === 0 ? 'bg-yellow-100 ' : 'bg-white'}`}>
                                <td className="border px-2 py-1">{index + 1}</td>
                                <td className="border px-2 py-1">{peserta.nama}</td>
                                <td className="pl-3 text-center align-middle">
                                    <BackspaceIcon
                                        onClick={() => clearInput(peserta.id_peserta, index)}
                                        className="h-8 w-8 rotate-180 px-1 py-0.5 text-white bg-red-500 rounded-lg"
                                    />
                                </td>
                                <td className="border px-2 py-1">
                                    <input
                                        ref={inputRefs.current[index]} // Attach ref to the input field
                                        type={selectedMapel?.nama_mapel?.toLowerCase() === "musyawarah" ? "text" : "number"}
                                        maxLength={selectedMapel?.nama_mapel?.toLowerCase() === "musyawarah" ? 1 : 3}
                                        defaultValue={mapel.isi_nilai}
                                        onChange={(e) => handleIsiNilaiChange(peserta.id_peserta, e.target.value)}
                                        className="p-1 border rounded w-full text-center"
                                    />
                                </td>

                                <td className="border px-2 py-1">
                                    <button
                                        onClick={() => handleSimpan(peserta.id_peserta)}
                                        className={`px-2 py-2 ${editedRows[peserta.id_peserta] ? 'bg-green-500 text-white ' : 'bg-gray-200 text-gray-400'} rounded`}
                                        disabled={!editedRows[peserta.id_peserta]}
                                    >
                                        Simpan
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </Modal>


            {isModalRapotOpen && (
                <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-gray-50 rounded-lg shadow-lg w-full max-w-4xl h-full overflow-auto p-4">
                        <div className="flex justify-between items-center">
                            <h2 className="text-xl font-bold">Nilai Rapot</h2>
                            <button
                                onClick={closeModalRapot}
                                className="text-white bg-red-500 px-4 py-2 rounded"
                            >
                                Tutup
                            </button>
                        </div>

                        <div className="mr-10 rounded-xl">
                            <table className="min-w-full bg-white mt-4">
                                <thead>
                                <tr>
                                    <th className="border px-2 py-2 sticky top-0 z-10 bg-white">No</th>
                                    <th className="border px-1 py-2 sticky top-0 z-10 bg-white">Nama</th>
                                    {/* Sort nilai_rapot by no_urut before rendering */}
                                    {listNilaiRapotPeserta[0]?.nilai_rapot
                                        .sort((a, b) => a.id_nilai - b.id_nilai)
                                        .map((nilai) => (
                                            <th
                                                key={nilai.id_nilai}
                                                className={`sticky top-0 z-10 border px-4 py-2 rotate-90 text-black ${getBgColorByKategoriFan(
                                                    nilai.kategori_fan
                                                )}`}
                                                style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)' }}
                                            >
                                                {nilai.nama_nilai}
                                            </th>
                                        ))}
                                </tr>
                                </thead>
                                <tbody>
                                {listNilaiRapotPeserta.map((peserta,index) => (
                                    <tr key={peserta.id_peserta}>
                                        <td className={`border px-1 py-2 text-center font-bold whitespace-nowrap text-sm ${index % 2 === 0 ? 'bg-yellow-100' : 'bg-white'}`}>{index+1}</td>
                                        <td className={`border px-1 py-2 sticky left-0 font-bold  text-sm ${index % 2 === 0 ? 'bg-yellow-100' : 'bg-white'}`}>{peserta.nama}</td>
                                        {/* Sort nilai_rapot by id_nilai before rendering */}
                                        {peserta.nilai_rapot
                                            .sort((a, b) => a.id_nilai - b.id_nilai)
                                            .map((nilai) => (
                                                <td
                                                    key={nilai.id_nilai}
                                                    className={`${(nilai.isi_nilai <=5 && nilai.id_nilai <=29)? 'text-red-500':'text-black'} border px-4 py-2 text-center font-bold 
                                                    ${index % 2 === 0 ? `${nilai.top3===true ? 'bg-green-500':'bg-yellow-100'}` : `${nilai.top3===true ? 'bg-green-500':'bg-white'}`}`}
                                                >
                                                    {nilai.isi_nilai}
                                                </td>
                                            ))}

                                        <td className={`border px-4 py-2 whitespace-nowrap text-sm ${index % 2 === 0 ? `{bg-yellow-100}` : 'bg-white'}`}>{peserta.nama}</td>
                                    </tr>
                                ))}

                                </tbody>




                                <thead>
                                <tr>
                                    <th className="border px-2 py-2 sticky top-0 z-10 bg-white text-xs">No</th>
                                    <th className="border px-1 py-2 sticky top-0 z-10 bg-white text-xs">Rata-rata Kelas</th>
                                    {/* Sort nilai_rapot by no_urut before rendering */}
                                    {listNilaiRapotPesertaRerata
                                        .map((nilai,index) => (
                                            <th
                                                key={index}
                                                className={`sticky top-0 z-10 border px-4 py-2 text-black text-sm ${getBgColorByKategoriFan(
                                                    nilai.kategori_fan
                                                )}`}
                                            >
                                                {nilai.nilai_rapot}
                                            </th>
                                        ))}
                                </tr>
                                </thead>


                            </table>
                        </div>
                    </div>
                </div>
            )}

        </div>

        </>
    );
};

export default RekapNilai;
